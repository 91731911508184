import { useParams } from 'react-router-dom'
import ClinicalTrialSkeleton from '../features/clinical-trial/components/clinical-trial-skeleton'
import ClinicalTrialDetailInfo from '../features/clinical-trial/components/clinical-trial-detail-info'
import ClinicalTrialDetailFooterContent from '../features/clinical-trial/components/clinical-trial-detail-footer-content'
import { useClinicalTrialQuery } from '../features/clinical-trial/queries/clinical-trial-queries'

export default function ClinicalTrialDetail() {
  const { id } = useParams()
  const { data: clinicalTrial, isLoading: isQueryLoading } = useClinicalTrialQuery(id)

  if (isQueryLoading || !clinicalTrial) {
    return <ClinicalTrialSkeleton />
  }

  return (
    <div className="h-full md:min-h-[calc(100vh-200px)] min-h-[calc(100vh-220px)] flex flex-col items-center justify-between gap-10 mx-auto my-4">
      <ClinicalTrialDetailInfo
        clinicalTrial={clinicalTrial}
      />
      <ClinicalTrialDetailFooterContent
        clinicalTrial={clinicalTrial}
      />
    </div>
  )
}
