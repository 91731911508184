import ResearchCenterFilters from '../../../interfaces/research-center/research-center-filters'
import { api } from '../../../libs'
import { ResearchCenter } from '../../../models'

const fetchResearchCenters = async (filters?: ResearchCenterFilters): Promise<ResearchCenter[]> => {
  const getResearchCentersResponse = await api.researchCenters.getResearchCenters(filters)
  return getResearchCentersResponse
}

const researchCenterService = {
  fetchResearchCenters,
}

export default researchCenterService
