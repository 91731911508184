import { QueryFunctionContext, useQuery } from 'react-query'
import { ResearchCenter } from '../../models'
import QueryParams from '../../interfaces/queries/query-params'
import researchCenterService from './services/research-center-service'
import ResearchCenterFilters from '../../interfaces/research-center/research-center-filters'

const researchCenterKeys = {
  all: [{ scope: 'researchCenter' }] as const,
  lists: () => [{ ...researchCenterKeys.all[0], entity: 'list' }] as const,
  list: (filter: ResearchCenterFilters) => [{
    ...researchCenterKeys.lists()[0], filter,
  }] as const,
  single: (id: string) => [{ ...researchCenterKeys.all[0], entity: 'single', id }] as const,
}

export const useResearchCentersQuery = <ModelSchema = ResearchCenter[]>(params?: QueryParams<ResearchCenter[], ModelSchema>) => useQuery({
  queryKey: researchCenterKeys.lists(),
  queryFn: () => researchCenterService.fetchResearchCenters(),
  select: params?.select,
})

export const useFilteredResearchCentersQuery = <ModelSchema = ResearchCenter[]>(
  filters: ResearchCenterFilters,
  params?: QueryParams<ResearchCenter[], ModelSchema>,
) => useQuery({
    queryKey: researchCenterKeys.list(filters),
    queryFn: async ({
      queryKey: [{ filter }],
    }: QueryFunctionContext<ReturnType<typeof researchCenterKeys['list']>>) => researchCenterService.fetchResearchCenters(filter),
    select: params?.select,
  })
