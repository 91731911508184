import { Button, Title } from '@mantine/core'
import { useTranslation } from 'react-i18next'
import { FormProvider, useForm } from 'react-hook-form'
import { useParams } from 'react-router-dom'
import EligibilityQuestionItem from './eligibility-question-item'
import EligibilityTestAttributes from '../../../interfaces/eligibility-test/eligibility-test-attributes'
import EligibilityTestFormSkeleton from './eligibility-test-form-skeleton'
import { useClinicalTrialQuery } from '../../clinical-trial/queries/clinical-trial-queries'
import { useSubmitApplicationEligibilityTestResponse } from '../../healthcare-professional/queries'
import { ExtendedHealthcareProfessionalApplication } from '../../../models'
import EligibilityTestResponseResult from '../../../interfaces/eligibility-test/eligibility-test-result'
import { EligibilityQuestionCriterionType } from '../enum'

interface Props {
  healthcareProfessionalApplication: ExtendedHealthcareProfessionalApplication
  onEligibilityTestResponseConclusion: (eligibilityTestResponse: EligibilityTestResponseResult) => void
}

export default function EligibilityTestForm({ healthcareProfessionalApplication, onEligibilityTestResponseConclusion }: Props) {
  const { t } = useTranslation()

  const { clinicalTrialId } = useParams()
  const { data: clinicalTrial, isLoading: isQueryLoading } = useClinicalTrialQuery(clinicalTrialId)
  const { mutate, isLoading: isMutationLoading } = useSubmitApplicationEligibilityTestResponse()

  const useFormMethods = useForm<EligibilityTestAttributes>({ mode: 'onTouched' })
  const {
    handleSubmit, formState: { isValid }, watch,
  } = useFormMethods

  const eligibilityQuestionResponses = watch('eligibilityQuestionResponses')

  const isSubmitDisabled = () => {
    if (!isValid || isQueryLoading || !clinicalTrial || !eligibilityQuestionResponses) return true
    const allResponsesFilled = clinicalTrial.eligibilityTest.eligibilityQuestions.every(
      (_, index) => eligibilityQuestionResponses[index] && eligibilityQuestionResponses[index],
    )
    return !allResponsesFilled
  }

  if (isQueryLoading || !clinicalTrial) {
    return (
      <EligibilityTestFormSkeleton />
    )
  }

  const elibilityQuestionsInclusionCriteria = clinicalTrial.eligibilityTest.eligibilityQuestions.filter(
    (question) => question.criterionType === EligibilityQuestionCriterionType.Inclusion,
  )

  const elibilityQuestionsExclusionCriteria = clinicalTrial.eligibilityTest.eligibilityQuestions.filter(
    (question) => question.criterionType === EligibilityQuestionCriterionType.Exclusion,
  )

  return (
    <FormProvider {...useFormMethods}>
      <div className="flex flex-col xl:w-200 lg:w-140 md:w-140 sm:mx-auto mx-2 sm:mt-5 mt-8">
        <Title order={1} fw={600} c="gray.9">{t('common:eligibility-test')}</Title>
        <form
          className="flex flex-col gap-5 mt-4"
          onSubmit={
            handleSubmit((eligibilityTestAttributes) => mutate({
              eligibilityQuestionResponses: eligibilityTestAttributes.eligibilityQuestionResponses,
              clinicalTrialId: clinicalTrial.id,
              healthcareProfessionalApplicationId: healthcareProfessionalApplication.id,
            }, {
              onSuccess: (eligibilityTestResponse) => onEligibilityTestResponseConclusion(eligibilityTestResponse),
            }))
}
        >
          {elibilityQuestionsInclusionCriteria.length > 0 && (
            <div className="flex flex-col gap-3">
              <Title fw={500} order={3} c="gray.8">{t('clinicalTrial:eligibility-question:inclusion-criteria')}</Title>
              {elibilityQuestionsInclusionCriteria.map((eligibilityQuestion, index) => (
                <EligibilityQuestionItem key={eligibilityQuestion.id} index={index} indexKey={index} eligibilityQuestion={eligibilityQuestion} />
              ))}
            </div>
          )}
          {elibilityQuestionsExclusionCriteria.length > 0 && (
            <div className="flex flex-col gap-3">
              <Title fw={500} order={3} c="gray.8">{t('clinicalTrial:eligibility-question:exclusion-criteria')}</Title>
              {elibilityQuestionsExclusionCriteria.map((eligibilityQuestion, index) => (
                <EligibilityQuestionItem
                  key={eligibilityQuestion.id}
                  index={index}
                  indexKey={elibilityQuestionsInclusionCriteria.length + index}
                  eligibilityQuestion={eligibilityQuestion}
                />
              ))}
            </div>
          )}
          <Button
            color="violet"
            disabled={isSubmitDisabled()}
            loading={isMutationLoading}
            className="w-40 mt-1 ml-auto sm:text-sm text-md sm:h-8 h-10"
            type="submit"
          >
            {t('clinicalTrial:eligibility-test:send-checklist')}
          </Button>
        </form>
      </div>
    </FormProvider>
  )
}
