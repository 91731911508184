import {
  UnstyledButton, Text,
} from '@mantine/core'
import { IconType } from 'react-icons'

interface Props<ModelSchema> {
  value: ModelSchema
  label: string
  checked: boolean | null
  onChange: (value: ModelSchema) => void
  Icon?: IconType
}

export default function ToggleCardButton<ModelSchema>({
  value, label, checked, onChange, Icon,
}: Props<ModelSchema>) {
  return (
    <div className="2xl:w-[40%] sm:w-[30%]">
      <UnstyledButton
        className="w-full flex items-center gap-3 p-3 px-6 bg-white border border-solid border-gray-300 rounded-md text-nowrap
          transition-[border-color] ease-in-out delay-50
         data-[checked='true']:bg-violet-50 data-[checked='true']:border-violet-900"
        data-checked={checked}
        onClick={() => onChange(value)}
      >
        {Icon
        && (
        <div className="w-6 h-6 flex items-center justify-center rounded-full bg-violet-100">
          <Icon size={16} color="#5f3dc4" />
        </div>
        )}
        <Text className="font-500 border-1" c="gray.7">{label}</Text>
      </UnstyledButton>
    </div>
  )
}
