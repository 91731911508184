import { kyInstance } from '../config/ky'
import GetResearchCentersRequest from '../requests/research-centers/get-research-centers-request'
import GetResearchCentersResponse from '../responses/research-centers/get-research-centers-response'

const RESEARCH_CENTERS_BASE_URL = 'research-centers'

const getResearchCenters = async (request?: GetResearchCentersRequest) => kyInstance
  .get(RESEARCH_CENTERS_BASE_URL, {
    searchParams: request?.clinicalTrialId ? { clinicalTrialId: request.clinicalTrialId } : undefined,
  })
  .json<GetResearchCentersResponse>()

export default {
  getResearchCenters,
}
