import { ReactElement, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import HealthcareProfessionalForm from './healthcare-professional-form-content'
import UserAuth from '../../auth/components/user-auth'
import { HealthcareProfessionalAuthStage } from '../enum'
import { SignUpSession } from '../../../interfaces/auth'
import { HealthcareProfessionalAttributes } from '../../../interfaces/healthcare-professional'
import { healthcareProfessionalToSignUpAttributes } from '../utils/healthcare-professional-attributes-utils'
import healthcareProfessionalService from '../services/healthcare-professional-service'
import { useAuthUserActions } from '../../auth/store/auth-user-store'
import HealthcareProfessionalFavoriteResearchCenter from './healthcare-professional-favorite-center'

export default function HealthcareProfessionalAuth() {
  const [healthcareProfessionalAuthStage, setHealthcareProfessionalAuth] = useState<HealthcareProfessionalAuthStage>(HealthcareProfessionalAuthStage.userAuth)
  const [signUpSession, setSignUpSession] = useState<SignUpSession | null>(null)

  const useFormMethods = useForm<HealthcareProfessionalAttributes>({ mode: 'onTouched' })

  const { setLoggedUser } = useAuthUserActions()

  const handleSignUpInitiate = (session: SignUpSession) => {
    setSignUpSession(session)
    setHealthcareProfessionalAuth(HealthcareProfessionalAuthStage.healthcareProfessionalForm)
  }

  const handleHealthcaProfessionalInformationComplete = () => {
    setHealthcareProfessionalAuth(HealthcareProfessionalAuthStage.favoriteResearchCenter)
  }

  const handleSignUpHealthcareProfessional = async (healtcareProfessionalAttributes: HealthcareProfessionalAttributes) => {
    if (!signUpSession) return
    const signUpAttributes = healthcareProfessionalToSignUpAttributes(healtcareProfessionalAttributes, signUpSession)
    const userSession = await healthcareProfessionalService.signUpHealthcareProfessional(signUpAttributes, signUpSession.signUpToken)
    setLoggedUser(userSession.user)
  }

  const stageComponentMap: { [key in HealthcareProfessionalAuthStage]: ReactElement } = {
    [HealthcareProfessionalAuthStage.userAuth]: <UserAuth onSignUpInitiate={handleSignUpInitiate} />,
    [HealthcareProfessionalAuthStage.healthcareProfessionalForm]: <HealthcareProfessionalForm
      onHealthcareProfessionalInformationComplete={handleHealthcaProfessionalInformationComplete}
    />,
    [HealthcareProfessionalAuthStage.favoriteResearchCenter]: <HealthcareProfessionalFavoriteResearchCenter
      onHealthcareProfessionalSignUpSubmit={handleSignUpHealthcareProfessional}
    />,
  }

  return (
    <div className="2xl:w-140 xl:w-140 lg:w-140 md:w-140 sm:mx-auto mx-2 sm:mt-5 mt-8">
      <FormProvider {...useFormMethods}>
        {stageComponentMap[healthcareProfessionalAuthStage]}
      </FormProvider>
    </div>
  )
}
