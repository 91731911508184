import {
  UnstyledButton, Group, Text, Tabs,
} from '@mantine/core'
import { useEffect, useRef, useState } from 'react'
import { useViewportSize } from '@mantine/hooks'
import { FaChevronDown, FaChevronUp } from 'react-icons/fa6'
import { useTranslation } from 'react-i18next'
import { ExtendedClinicalTrial } from '../../../models'

interface Props {
  clinicalTrial: ExtendedClinicalTrial
}

export default function ClinicalTrialDetailDescription({ clinicalTrial }: Props) {
  const { t } = useTranslation()
  const { height: viewportHeight } = useViewportSize()

  const descriptionMaxHeight = Math.max(viewportHeight * 0.25, 120)
  const TOLERANCE_HEIGHT = 40

  const [descriptionExpanded, setDescriptionExpanded] = useState(false)
  const [isDescriptionOverflowing, setIsDescriptionOverflowing] = useState(true)

  const descriptionRef = useRef<HTMLDivElement>(null)

  const toggleDescriptionExpanded = () => setDescriptionExpanded((prev) => !prev)

  useEffect(() => {
    if (descriptionRef.current) {
      const actualHeight = descriptionRef.current.scrollHeight
      setIsDescriptionOverflowing(actualHeight > descriptionMaxHeight + TOLERANCE_HEIGHT)
    }
  }, [descriptionMaxHeight, clinicalTrial.description])

  return (
    <div className="flex flex-col w-full">
      <Tabs defaultValue="summary">
        <Tabs.List>
          <Tabs.Tab value="summary">
            {t('clinicalTrial:detail:description')}
          </Tabs.Tab>
          {clinicalTrial.eligibilityTest.inclusionDescription || clinicalTrial.eligibilityTest.exclusionDescription ? (
            <Tabs.Tab value="checklist">
              {t('clinicalTrial:detail:checklist')}
            </Tabs.Tab>
          ) : null}
        </Tabs.List>

        <div className="w-full">
          <Tabs.Panel value="summary" className="py-6 w-full">
            <Group
              ref={descriptionRef}
              mah={!descriptionExpanded && isDescriptionOverflowing ? descriptionMaxHeight : undefined}
              className="relative transition-all ease-in-out duration-300 overflow-hidden"
            >
              <Text size="md" c="gray.9" fw={600}>{t('clinicalTrial:detail:know-more')}</Text>
              <Text
                size="sm"
                className="text-gray-700"
                dangerouslySetInnerHTML={{ __html: clinicalTrial.description }}
              />
              {!descriptionExpanded && isDescriptionOverflowing && (
              <div className="absolute inset-x-0 bottom-0 h-4 bg-gradient-to-b from-transparent to-white" />
              )}
            </Group>
            {isDescriptionOverflowing && (
            <UnstyledButton
              variant="subtle"
              size="xs"
              className="mt-2"
              onClick={toggleDescriptionExpanded}
            >
              {descriptionExpanded ? (
                <div className="flex gap-3 items-center">
                  <Text size="md" fw={500} className="text-gray-900">
                    {t('clinicalTrial:detail:show-less')}
                  </Text>
                  <FaChevronUp size={14} className="text-gray-500" />
                </div>
              ) : (
                <div className="flex gap-3 items-center">
                  <Text size="md" fw={500} className="text-gray-900">
                    {t('clinicalTrial:detail:read-more')}
                  </Text>
                  <FaChevronDown size={14} className="text-gray-500" />
                </div>
              )}
            </UnstyledButton>
            )}
          </Tabs.Panel>

          <Tabs.Panel value="checklist" className="py-6 w-full">
            <div className="flex flex-col gap-5 w-full">
              {clinicalTrial.eligibilityTest.inclusionDescription && (
                <div className="flex flex-col gap-3">
                  <Text size="md" fw={600} c="gray.9">{t('clinicalTrial:detail:inclusion-criteria')}</Text>
                  <div className="flex flex-col gap-3 w-full">
                    <Text
                      size="sm"
                      className="text-gray-700"
                      dangerouslySetInnerHTML={{ __html: clinicalTrial.eligibilityTest.inclusionDescription ?? '' }}
                    />
                  </div>
                </div>
              )}
              {clinicalTrial.eligibilityTest.exclusionDescription && (
                <div className="flex flex-col gap-3">
                  <Text size="md" fw={600} c="gray.9">{t('clinicalTrial:detail:exclusion-criteria')}</Text>
                  <div className="flex flex-col gap-3 w-full">
                    <Text
                      size="sm"
                      className="text-gray-700"
                      dangerouslySetInnerHTML={{ __html: clinicalTrial.eligibilityTest.exclusionDescription ?? '' }}
                    />
                  </div>
                </div>
              )}
            </div>
          </Tabs.Panel>
        </div>
      </Tabs>
    </div>
  )
}
