import { ClinicalTrialFilter } from '../../../interfaces/clinical-trial/clinical-trial-filter'
import { ExtendedClinicalTrial } from '../../../models'

const getFilteredClinicalTrials = (clinicalTrials: ExtendedClinicalTrial[], filter: ClinicalTrialFilter) => {
  const { researchCenterId, healthcareSpecialties } = filter

  return clinicalTrials.filter((clinicalTrial) => {
    const isResearchCenterMatch = !researchCenterId || !!clinicalTrial.researchCenters.find((center) => center.id === researchCenterId)
    const isHealthcareSpecialtyMatch = healthcareSpecialties.length === 0 || healthcareSpecialties.includes(clinicalTrial.healthcareSpecialty)
    return isResearchCenterMatch && isHealthcareSpecialtyMatch
  })
}

export default getFilteredClinicalTrials
