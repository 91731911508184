import { Badge, Button, Title } from '@mantine/core'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { Path } from '../../../shared/enums'
import { getPath } from '../../../shared/utils/demo'
import { ExtendedClinicalTrial } from '../../../models'
import ClinicalTrialDetailDescription from './clinical-trial-detail-description'

interface Props {
  clinicalTrial: ExtendedClinicalTrial;
}

export default function ClinicalTrialDetailInfo({ clinicalTrial }: Props) {
  const navigate = useNavigate()
  const { t } = useTranslation()

  return (
    <div className="flex flex-col items-center gap-5 w-full">
      <div className="w-[95%] 2xl:w-[92%]">
        <div className="w-full max-w-400 h-full rounded-xl py-8 lg:py-10 2xl:py-12 bg-cover bg-no-repeat bg-center bg-clinical-trial-background-image">
          <div className="flex flex-col w-[93%] m-auto">
            <Title order={3} fw={600} className="text-white">{clinicalTrial.title}</Title>
            {clinicalTrial.subtitle && <Title order={4} fw={500} className="text-white mb-3">{clinicalTrial.subtitle}</Title>}
            <Button
              bg="white"
              c="violet.7"
              className="sm:w-60 w-full xl:text-md sm:text-sm text-lg xl:h-10 sm:h-10 h-12 mt-4"
              onClick={() => navigate(getPath(Path.HealthCareProfessionalApplication.replace(':clinicalTrialId', clinicalTrial.id)))}
            >
              {t('clinicalTrial:detail:register-patient')}
            </Button>
          </div>
        </div>
      </div>
      <div className="flex flex-col gap-5 w-[88%]">
        <div className="flex flex-col">
          <Title order={6} fw={400} c="gray.6" mb={5}>{t('clinicalTrial:detail:research-centers')}</Title>
          <div className="flex gap-2">
            {clinicalTrial.researchCenters.map((researchCenter) => (
              <Badge color="gray.1" c="black" tt="none" size="lg" fw="400" key={researchCenter.id}>{researchCenter.name}</Badge>
            ))}
          </div>
        </div>
        <div className="2xl:w-8/12 xl:w-10/12 lg:mx-1">
          <ClinicalTrialDetailDescription clinicalTrial={clinicalTrial} />
        </div>
      </div>
    </div>
  )
}
