import { Controller, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import {
  Button, Select, Text, Title,
} from '@mantine/core'
import { useParams } from 'react-router-dom'
import { useFilteredResearchCentersQuery } from '../../research-center/queries'
import { useResearchCenterName } from '../../research-center/store/research-center-store'
import buildResearchCenterOptions from '../../research-center/utils/build-research-center-options'
import { HealthcareProfessionalAttributes } from '../../../interfaces/healthcare-professional'
import { images } from '../../../shared/utils/public'

interface Props {
  onHealthcareProfessionalSignUpSubmit: (healtcareProfessionalAttributes: HealthcareProfessionalAttributes) => void
}

export default function HealthcareProfessionalFavoriteResearchCenter({ onHealthcareProfessionalSignUpSubmit }: Props) {
  const { t } = useTranslation()
  const { clinicalTrialId } = useParams()

  const researchCenterName = useResearchCenterName()

  const { data: researchCentersOptions } = useFilteredResearchCentersQuery({ clinicalTrialId }, { select: buildResearchCenterOptions })

  const selectedResearchCenter = researchCentersOptions?.find(
    (researchCenter) => researchCenter.label.toLocaleLowerCase().trim() === researchCenterName?.toLocaleLowerCase().trim(),
  )

  const {
    control, setValue, handleSubmit,
  } = useFormContext<HealthcareProfessionalAttributes>()

  if (selectedResearchCenter) setValue('researchCenterId', selectedResearchCenter.value)

  return (
    <div className="flex flex-col">
      <img
        className="md:h-65 h-35 object-contain my-5"
        src={images.checkmarkTestTube.src}
        alt={images.checkmarkTestTube.alt}
      />
      <Title order={1} fw={600} c="gray.9">{t('healthcareProfessional:auth:favorite-research-center')}</Title>
      <Text className="mt-2" c="gray.6">{t('healthcareProfessional:auth:favorite-research-center-info')}</Text>
      <form className="flex flex-col mt-5" onSubmit={handleSubmit(onHealthcareProfessionalSignUpSubmit)}>
        <Controller
          name="researchCenterId"
          control={control}
          rules={{
            required: { value: true, message: t('common:form-error:required-field') },
          }}
          render={({ field }) => (
            <Select
              label={t('clinicalTrial:research-center')}
              placeholder={t('clinicalTrial:research-center')}
              data={researchCentersOptions}
              searchable
              {...field}
            />
          )}
        />
        <div className="flex flex-col gap-2 mt-6">
          <Button
            fullWidth
            color="violet"
            className="mt-1 sm:text-sm text-md sm:h-8 h-10"
            type="submit"
          >
            {t('common:continue')}
          </Button>
          <Button
            fullWidth
            variant="default"
            color="gray.8"
            className="mt-1 sm:text-sm text-md sm:h-8 h-10"
            onClick={handleSubmit(
              (healtcareProfessionalAttributes) => onHealthcareProfessionalSignUpSubmit({ ...healtcareProfessionalAttributes, researchCenterId: null }),
            )}
          >
            {t('healthcareProfessional:auth:continue-without-research-center')}
          </Button>
        </div>
      </form>
    </div>
  )
}
