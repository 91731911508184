import {
  ActionIcon,
  AppShell, Button, Image, Text, rem,
} from '@mantine/core'
import { ReactElement } from 'react'
import { FiMessageCircle } from 'react-icons/fi'
import { useTranslation } from 'react-i18next'
import { useHeadroom } from '@mantine/hooks'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { images } from '../../../shared/utils/public'
import { Path } from '../../../shared/enums'
import { getPath } from '../../../shared/utils/demo'
import { useResearchCenterStoreActions } from '../../research-center/store/research-center-store'

interface Props {
  children: ReactElement;
}

export default function PageWrapper({ children }: Props) {
  const navigate = useNavigate()
  const { t } = useTranslation(['common'])
  const pinned = useHeadroom({ fixedAt: 60 })
  const researchCenterStoreActions = useResearchCenterStoreActions()

  const [searchParams] = useSearchParams()
  const researchCenterNameQueryParam = searchParams.get('research-center')
  if (researchCenterNameQueryParam) researchCenterStoreActions.setResearchCenterName(researchCenterNameQueryParam)

  return (
    <AppShell
      header={{ height: 80, collapsed: !pinned, offset: false }}
      footer={{ height: 80 }}
    >
      <AppShell.Header className="w-100vw">
        <div className="h-full flex justify-between items-center px-5">
          <Image
            className="cursor-pointer"
            h={20}
            src={images.resconnLogo.src}
            alt={images.resconnLogo.alt}
            w="auto"
            onClick={() => navigate(getPath(Path.ClinicalTrials))}
          />
          <Button className="sm:text-sm text-md sm:h-8 h-10" bg="violet.6" visibleFrom="sm">
            {t('common:header:contact-us')}
          </Button>
          <ActionIcon hiddenFrom="sm" variant="transparent">
            <FiMessageCircle size={24} />
          </ActionIcon>
        </div>
      </AppShell.Header>
      <AppShell.Main
        pt={`calc(${rem(60)} + var(--mantine-spacing-md))`}
        pb={0}
        className="md:min-h-[calc(100vh-80px)] min-h-[calc(100vh-70px)]"
      >
        {children}
      </AppShell.Main>
      <AppShell.Footer className="w-full relative left-0 bottom-0">
        <div className="flex flex-col justify-center items-center sm:flex-row sm:justify-between gap-3 h-full px-5 bg-violet-800">
          <Image h={20} src={images.whiteResconnLogo.src} alt={images.whiteResconnLogo.alt} w="auto" />
          <Text c="white" className="text-sm sm:text-md">
            {t('common:footer:resconn-rights')}
          </Text>
        </div>
      </AppShell.Footer>
    </AppShell>
  )
}
