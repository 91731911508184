import { Text } from '@mantine/core'
import { LuPill } from 'react-icons/lu'
import { TbReport, TbStethoscope } from 'react-icons/tb'
import { useTranslation } from 'react-i18next'
import { ExtendedClinicalTrial } from '../../../models'

interface Props {
  clinicalTrial: ExtendedClinicalTrial;
}

export default function ClinicalTrialDetailFooterContent({ clinicalTrial }: Props) {
  const { t } = useTranslation()

  return (
    <div className="w-[88%]">
      <div className="w-fit flex flex-col lg:gap-4 gap-4">
        <Text size="md" fw={600} c="gray.9">{t('clinicalTrial:detail:info')}</Text>
        <div className="grid md:grid-cols-3 grid-cols-2 gap-6">
          <div className="flex items-center gap-3">
            <LuPill size={30} color="#BDB4FE" />
            <div className="flex flex-col">
              <Text size="sm" c="gray.7">{t('clinicalTrial:detail:intervention-info')}</Text>
              <Text fw={500} c="gray.8">{clinicalTrial.intervention.name}</Text>
            </div>
          </div>
          <div className="flex items-center gap-3">
            <TbStethoscope size={30} color="#BDB4FE" />
            <div className="flex flex-col">
              <Text size="sm" c="gray.7">{t('clinicalTrial:detail:specialty-info')}</Text>
              <Text fw={500} c="gray.8">
                {t(`clinicalTrial:healthcare-specialty:${clinicalTrial.healthcareSpecialty}`)}
              </Text>
            </div>
          </div>
          <div className="flex items-center gap-3">
            <TbReport size={30} color="#BDB4FE" />
            <div className="flex flex-col">
              <Text size="sm" c="gray.7">{t('clinicalTrial:detail:sponsor-info')}</Text>
              <Text fw={500} c="gray.8">{clinicalTrial.sponsor.name}</Text>
            </div>
          </div>
        </div>

      </div>
    </div>
  )
}
