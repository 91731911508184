import { create } from 'zustand'

interface ResearchCenterStore {
  researchCenter: {
    name: string | null
  },
  actions: {
    setResearchCenterName: (researchCenterName: string | null) => void;
  }
}

const useResearchCenterStore = create<ResearchCenterStore>((set) => ({
  researchCenter: {
    name: null,
  },
  actions: {
    setResearchCenterName: (researchCenterName) => set((state) => ({ researchCenter: { ...state.researchCenter, name: researchCenterName } })),
  },
}))

export const useResearchCenterName = () => useResearchCenterStore((state) => state.researchCenter.name)
export const useResearchCenterStoreActions = () => useResearchCenterStore((state) => state.actions)
